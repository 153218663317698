import _isEmpty from "lodash/isEmpty";
import React, { useMemo } from 'react';
import Icon from "../../icon";
import Text from "../../text";
import View from "../../view";
import { PickerFieldTypes } from "../types";
import { Typography } from "../../../style";
const dropdown = require("../assets/dropdown.png");
const useFieldType = props => {
  const {
    fieldType,
    preset,
    trailingAccessory,
    label,
    placeholder,
    style,
    labelStyle,
    testID
  } = props;
  const propsByFieldType = useMemo(() => {
    if (fieldType === PickerFieldTypes.filter) {
      return {
        preset: preset || null,
        containerStyle: {
          flexDirection: 'row'
        },
        labelStyle: Typography.text70,
        trailingAccessory: trailingAccessory ?? <Icon marginL-s1 source={dropdown} />
      };
    } else if (fieldType === PickerFieldTypes.settings) {
      return {
        preset: preset || null,
        label: undefined
      };
    }
  }, [fieldType, preset, trailingAccessory]);
  const pickerInnerInput = useMemo(() => {
    if (fieldType === PickerFieldTypes.filter) {
      return <Text text70 numberOfLines={1} style={style} testID={`${testID}.filter.type.label`}>
          {_isEmpty(label) ? placeholder : label}
        </Text>;
    } else if (fieldType === PickerFieldTypes.settings) {
      return <View flexG row spread>
          <Text text70 style={labelStyle} testID={`${testID}.settings.type.label`}>
            {label}
          </Text>
          <Text text70 $textPrimary style={style} testID={`${testID}.settings.type.placeholder`}>
            {_isEmpty(label) ? placeholder : label}
          </Text>
        </View>;
    }
  }, [style, labelStyle, fieldType, placeholder, label]);
  return {
    propsByFieldType,
    pickerInnerInput
  };
};
export default useFieldType;